import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Appointments, Login,} from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";


function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const flebo = JSON.parse(localStorage.getItem("flebo"));
    if (flebo) {
      dispatch(saveUser(flebo));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Appointments />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
