import { axiosInstance } from '../../api/config';
import { store } from '../../redux/store';
import { saveUser } from '../../redux/userSlice';

export const handleLogin = async (initialState, setErr) => {
  try {
    const response = await axiosInstance
      .post(
        `/loginfabloadmin`,
        {
          fablo_id: initialState.fablo_id.trim(),
          password: initialState.password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        const user = JSON.stringify(response.data.data);
        localStorage.setItem('accessToken', user.token);
        localStorage.setItem('flebo', user);
        store.dispatch(saveUser(response.data.data));
      });
    return response;
  } catch (error) {
    setErr({ global: 'Credentails Not Matching' });
    throw error;
  }
};


export const verifyEmail = async (email, setErr) => {
  try {
    const response = await axiosInstance.post(`/fablo/forgotpasswordemailverify`, { email_id:email }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Email Not Matching" });
    throw error;
  }
}

export const verifyOtp = async (email, otpforgotPassword, setErr) => {
  try {
    const response = await axiosInstance.post(`/fablo/forgotpasswordotpverify`, { email_id:email,otpforgotPassword }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" });
    throw error;
  }
}

export const submitNewPassword = async(email, password, setErr) => {
  try {
    const response = await axiosInstance.post(`/fablo/forgotpasswordotpset`, { email_id:email, password }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Password Change Failed" });
    throw error;
  }
}