import React, { useEffect, useMemo, useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import Table from "../Common/Table/Table";
import { updateStatus } from "../../utils/appointmentUtils/appointmentUtils";
import { toast } from "react-toastify";
import FleboStatus from "./FleboStatus";

const Appointments = ({
  appointments,
  setAppointmentsFn,
  setEditData,
  setOpenTracker,
}) => {
  const headings = useMemo(
    () => [
      "Name",
      "Phone",
      "Email",
      "Address",
      "Date",
      "Time",
      "Tests",
      "Packages",
      "Status",
      "Action",
    ],
    []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((data) => {
      const nameMatch = data?.user_id?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const numberMatch = data?.user_id?.phone_number
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const emailMatch = data?.user_id?.email_id
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const addressMatch = data?.address
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || numberMatch || emailMatch || addressMatch;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
  const start = (data) => {
    updateStatus({ is_accepted: true }, data?._id)
      .then((res) => {
        setAppointmentsFn();
        toast(`Phlebo Started`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast(`Phlebo Start Failed`, {
          type: "error",
          autoClose: 3000,
        });
      });
  };
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center items-center mb-5">
        <div className="flex w-full md:w-1/2">
          <SearchInput
            placeholder={"Search"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <>
          <div className="hidden md:block">
            <Table headings={headings}>
              {filteredData?.map((data, index) => (
                <tr key={index} className="relative">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {data?.user_id?.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 ">
                      {data?.user_id?.phone_number}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {data?.user_id?.email_id}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900 overflow-hidden text-ellipsis break-words">
                      {data?.address}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 ">{data?.date}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 ">{data?.time}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                      {data?.lab_test?.map((item, idx) => (
                        <span key={idx}>
                          {" "}
                          {idx + 1} {". "} {item?.test_name}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                      {data?.health_packages?.length > 0 ? (
                        data?.health_packages?.map((item, idx) => (
                          <span key={idx}>
                            {" "}
                            {idx + 1} {". "} {item?.health_package_name}
                          </span>
                        ))
                      ) : (
                        <span>No Packages !</span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <FleboStatus data={data} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {!data?.is_accepted ? (
                      <button
                        className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
                        onClick={() => {
                          start(data);
                        }}
                      >
                        Start
                      </button>
                    ) : (
                      <button
                        className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                        onClick={() => {
                          setEditData(data);
                          setOpenTracker(true);
                        }}
                      >
                        Update
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </Table>
          </div>
          <div className="block md:hidden space-y-4 mb-5 max-h-screen overflow-y-auto">
            {filteredData?.map((data, index) => (
              <div
                key={index}
                className="bg-white p-4 border  border-gray-400 border-dotted rounded-lg shadow-lg flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4"
              >
                <div className="md:w-1/5">
                  <div
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(90, 203, 52) 0%, rgb(0, 149, 209) 100%)",
                    }}
                    className="text-sm text-center border-b  rounded-md border-gray-400 text-white border-dotted p-2 mb-2"
                  >
                    <strong>Name :</strong> {data?.user_id?.name}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-t border-gray-400 border-dotted pt-4 pb-2 mb-2">
                    <strong>Phone :</strong> {data?.user_id?.phone_number}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-gray-400 border-dotted pb-2 mb-2">
                    <strong>Email :</strong> {data?.user_id?.email_id}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-gray-400 border-dotted pb-2 mb-2 overflow-hidden text-ellipsis break-words">
                    <strong>Address :</strong> {data?.address}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-gray-400 border-dotted pb-2 mb-2">
                    <strong>Date :</strong> {data?.date}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-gray-400 border-dotted pb-2 mb-2 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                    <strong>Tests :</strong>
                    {data?.lab_test?.map((item, idx) => (
                      <span key={idx}>
                        {idx + 1}. {item?.test_name}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-gray-400 border-dotted pb-2 mb-2 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                    <strong>Packages :</strong>
                    {data?.health_packages?.length > 0 ? (
                      data?.health_packages?.map((item, idx) => (
                        <span key={idx}>
                          {idx + 1}. {item?.health_package_name}
                        </span>
                      ))
                    ) : (
                      <span>No Packages!</span>
                    )}
                  </div>
                </div>
                <div className="md:w-1/5">
                  <div className="text-sm text-gray-900 border-b border-gray-400 border-dotted pb-2 mb-2 flex flex-wrap gap-1">
                    <strong>Status :</strong> <FleboStatus data={data} />
                  </div>
                </div>
                <div className="md:w-1/5 flex justify-center items-center">
                  {!data?.is_accepted ? (
                    <button
                      className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
                      onClick={() => start(data)}
                    >
                      Start
                    </button>
                  ) : (
                    <button
                      className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white px-5 py-2" 
                      onClick={() => {
                        setEditData(data);
                        setOpenTracker(true);
                      }}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <NoDataFound data={"appointments"} />
      )}
    </div>
  );
};

export default Appointments;
